<template>
    <div>
        <!-- <van-nav-bar title="报名记录" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div v-if="list.length" class="block">
            <div class="new_card" v-for="item in list" :key="item.index">
                <div class="new_card_on" @click="goDetails(item.job_id)">
                    <div class="new_card_on_lt"><img style="width:100%;height:100%" :src="item.img ? item.img : morenurl" /></div>
                    <div class="new_card_on_rt">
                        <div class="new_card_on_rt_cd1">{{item.factory_short_name ? item.factory_short_name : item.factory_name}}</div>
                        <div class="new_card_on_rt_cd2">{{item.wage ? item.wage+'元/小时' : item.salary+'元/月' }}</div>
                        <div class="new_card_on_rt_cd3">{{item.job_name}}</div>
                        <div class="new_card_on_rt_cd4">{{item.apply_time}}       <span v-if="item.wage_is_change" class="col_0ABBB9" style="font-weight:700;">工价已变动！</span></div>
                    </div>
                </div>
                <div class="new_card_shixiao" v-if="item.job_is_close">
                    <div class="new_card_shixiao_on">
                        <div class="new_card_shixiao_on_text">职位已失效</div>
                        <div class="new_card_shixiao_on_wenzi" @click="Delete(item.track_id)">x</div>
                    </div>
                </div>
            </div>
            <!-- <div v-for="item in list" :key="item.index" class="card">
                <div :class="['displayFlex', item.job_is_close ? 'hide' : '']" style="align-items: center;" @click="item.job_is_close ? '' : goDetails(item.job_id)">
                    <img class="logo" :src="item.img ? item.img : morenurl" />
                    <div class="info">
                        <div class="displayFlex justifyContentBetten" style="margin-bottom: 0.1rem;">
                            <div class="displayFlex">
                                <div class="com_name">{{item.factory_short_name ? item.factory_short_name : item.factory_name}}</div>
                            </div>
                            <div class="wage">{{item.wage ? item.wage+'元/小时' : item.salary+'元/月' }}</div>
                        </div>
                        <div class="displayFlex justifyContentBetten" style="margin-bottom: 0.1rem;">
                            <div class="job_name">{{item.job_name}}</div>
                            <div class="job_name" style="color: #999;">{{item.apply_time}}</div>
                        </div>
                        <div class="text" style="font-size:10px" v-if="item.wage_is_change">
                            工价已变动！
                        </div>
                    </div>
                </div>
                <div class="none" v-if="item.job_is_close">
                    <div class="text">
                        职位已失效
                    </div>
                    <div class="delete" @click="Delete(item.track_id)">
                        <img src="@/assets/img/delete.png" alt="">
                        移除
                    </div>
                </div>
            </div> -->
        </div>
        <div v-else class="null-data block">
            <div>
                <img :src="src+'null_index.png'" />
                <div>暂无报名记录</div>
            </div>
        </div>
        <!-- -->
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/index.css'
import config from "@/utils/config.js";
// import { getApplyList, deleteApply } from "@/api/home";
import { Toast } from 'vant';
export default {
    data() {
        return {
            src: `${config.ossImageUrl}`,
            morenurl:require('../../assets/morencaifeng.png'),
            list: [],
        };
    },
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.getList()
    },
    methods: {
        gobanck(){
            this.$router.go(-1);
        },

        // 跳转到详情
        goDetails(id) {
            this.$router.push({
                path: "/recruitmentDetails",
                query: { rid: id , type : 'apply'},
            });
        },
        // 删除报名记录
        Delete(id) {
            var data = {
                team_id: localStorage.getItem('team_id'),
                track_id: id
            }
            this.Request('client/apply','delete',data).then((res) => {
                if (res.status == 0) {
                    this.list.filter((v, i) => {
                        if (v.track_id == id) {
                            this.list.splice(i, 1)
                            Toast.success('操作成功');
                        }

                    })
                }
            })
        },
        onClickLeft() {
            this.$router.go(-1)
        },

        // 调接口，获取列表数据
        getList() {
            this.$store.state.refreshList = false
            var data = {
                team_id: localStorage.getItem('team_id'),
            }
            this.Request('client/apply/list', 'get', data).then((res) => {
                if (res.status == 0) {
                    this.list = res.data;
                }
            })
        },

    }
};
</script>
<style scoped>
.new_card_shixiao_on_wenzi{
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    color: #C1C1C1;
    text-align: center;
    position: absolute;
    right: 30px;
    bottom: 30px;
}
.new_card_shixiao_on_text{
    background-color: #E5E5E5;
    color: #999999;
    width: 21%;
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: absolute;
    font-size: 16px;
    transform: rotate(45deg);
    top: 22px;
    right: -36px;
}
.new_card_on_rt{
    width: calc(100% - 250px);
    height: 176px;
}
.new_card_on_rt_cd1{
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: left;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    margin-bottom: 10px;
    color: #1A1A1A;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.new_card_on_rt_cd2{
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0ABBB9;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.new_card_on_rt_cd3{
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1A1A1A;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.new_card_on_rt_cd4{
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.new_card_on_rt_cd4 span{font-size: 24px;}
.new_card_on_lt{
    width: 230px;
    height: 176px;
}
.new_card_on{
    width: calc(100% - 60px);
    height: 176px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

}
.new_card{
    width: 100%;
    height: 240px;
    background: #ffffff;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.new_card_shixiao{
    width: 100%;
    height: 240px;
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    left: 0;
    top: 0;
    z-index: 10;
}
.new_card_shixiao_on{
    width: 100%;
    height: 240px;
    position: relative;
    background: rgba(255, 255, 255, 0);
    overflow: hidden;
}
/* input的输入颜色 */
::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #fff;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}
.com_name {
    font-size: 28px;
}
.job_name {
    font-size: 26px;
}
/* 头部固定 */
.top {
    position: fixed;
    width: 100%;
    z-index: 2;
    /* margin-top: 90px; */
}

/* 列表里的搜索框 */
.search {
    padding: 30px;
    margin-top: -2px;
    background-color: #4c94f6;
}
.search .search_text {
    display: flex;
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 10px 20px;
    color: #fff;
    line-height: 40px;
}
.search .search_text .iconfont {
    color: #fff;
}
.search .search_text input {
    width: 84%;
    background: transparent;
    border: 0;
    color: #fff;
}
.search .search_text image {
    width: 32px;
    height: 32px;
    margin: 12px 20px 0 0;
}
/* 条件筛选 */
.sort {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    background-color: #fff;
    padding: 20px 60px;
}
.sort_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sort_icon > div {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin: 3px;
}

.sort_icon .sort_top {
    border-bottom: 10px solid #ccc;
}
.sort_icon .sort_bottom {
    border-top: 10px solid #ccc;
}
.screen {
    width: 40px;
    height: 40px;
}
.sort_title {
    margin-right: 10px;
}
.sort-select {
    font-weight: 700;
}
.sort .img {
    width: 40px;
    height: 40px;
}
/* 卡片 */
.block {
    position: relative;
    /* top: 110px; */
}
.hide {
    background: #fff;
    opacity: 0.3;
}
.none {
    text-align: center;
    font-size: 28px;
}
.none .text {
    position: absolute;
    line-height: 160px;
    font-weight: 700;
    text-align: center;
    width: 90%;
    top: 0;
}
.delete {
    position: absolute;
    padding: 0 30px;
    right: 0;
    position: absolute;
    bottom: 24px;
}
.delete img {
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
}
.card {
    background-color: #fff;
    padding: 20px 30px;
    margin-top: 20px;
    height: 120px;
    position: relative;
    align-items: center;
}
.card .logo {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}
.card .info {
    margin-left: 10px;
    width: calc(100% - 120px);
}
/* .card .info > div {
    height: 60px;
    line-height: 60px;
} */
.card .com_name {
    font-weight: 700;
    margin-right: 10px;
    max-width: 310px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.card .tips {
    border: 2px solid #666666;
    border-radius: 5px;
    color: #666666;
    padding: 0 6px;
    font-size: 20px;
    height: 32px;
    line-height: 32px;
    margin-top: 5px;
}
.card .tips.redcolor {
    border: 2px solid #ff2c2c;
    color: #ff2c2c;
}
.card .address {
    font-size: 28px;
}
.card .wage {
    color: #ff2c2c;
    font-weight: 700;
    font-size: 28px;
}

/* 不展示 */
.tips.color10 {
    color: #999999;
    border-color: #999999;
}
/* 已招满 */
.tips.color20 {
    color: #27b465;
    border-color: #27b465;
}
/* 招聘中 */
.tips.color30 {
    color: #4c94f6;
    border-color: #4c94f6;
}
/* 热招 */
.tips.color40 {
    color: #f8842c;
    border-color: #f8842c;
}
/* 急招 */
.tips.color50 {
    color: #ff2c2c;
    border-color: #ff2c2c;
}

.van-pull-refresh {
    overflow: initial;
}
</style>